<script setup lang="ts">
import useACL from "~/composables/useACL";
const { canEdit } = useACL();
const route = useRoute();

const isActive = (section: string) => {
  return route.path.includes(`/settings/${section}`);
};
</script>

<template>
  <div
    class="bg-background-400 dark:bg-background-100 text-background-100 dark:text-background-500"
  >
    <Sidebar />
    <div class="md:ml-32 md:p-12">
      <div
        class="bg-background-600 dark:bg-background-300 p-4 rounded-lg flex flex-col md:flex-row"
      >
        <aside
          class="md:border-r border-gray-300 dark:border-gray-600 flex flex-col gap-8 justify-center pr-4 md:h-[calc(100vh-8rem)] min-w-48 max-sm:my-12"
        >
          <nav class="flex flex-col items-start">
            <h3 class="uppercase text-sm text-gray-500">My Account</h3>
            <NuxtLink
              class="text-background-300 dark:text-background-600"
              :class="{ 'font-bold': isActive('account') }"
              to="/settings/account"
            >
              Account Info
            </NuxtLink>
          </nav>

          <nav
            v-if="canEdit('user_profiles')"
            class="flex flex-col items-start"
          >
            <h3 class="uppercase text-sm text-gray-500">Manage Users</h3>
            <NuxtLink
              class="text-background-300 dark:text-background-600"
              :class="{ 'font-bold': isActive('users') }"
              to="/settings/users"
            >
              Manage Users
            </NuxtLink>
            <NuxtLink
              class="text-background-300 dark:text-background-600"
              :class="{ 'font-bold': isActive('crews') }"
              to="/settings/crews"
            >
              Manage Crews
            </NuxtLink>
          </nav>

          <nav v-if="canEdit('equipment')" class="flex flex-col items-start">
            <h3 class="uppercase text-sm text-gray-500">
              Manage Equipment Info
            </h3>
            <NuxtLink
              class="text-background-300 dark:text-background-600"
              :class="{ 'font-bold': isActive('vendors') }"
              to="/settings/vendors"
            >
              Manage Vendors
            </NuxtLink>
            <NuxtLink
              class="text-background-300 dark:text-background-600"
              :class="{ 'font-bold': isActive('lenders') }"
              to="/settings/lenders"
            >
              Manage Lenders
            </NuxtLink>
            <NuxtLink
              class="text-background-300 dark:text-background-600"
              :class="{ 'font-bold': isActive('tags') }"
              to="/settings/tags"
            >
              Manage Tags
            </NuxtLink>
            <NuxtLink
              class="text-background-300 dark:text-background-600"
              :class="{ 'font-bold': isActive('categories') }"
              to="/settings/categories"
            >
              Manage Categories
            </NuxtLink>
          </nav>
        </aside>
        <main class="flex-grow md:pl-8">
          <h1>Settings</h1>
          <p class="text-lg text-gray-400 mb-4">
            Manage your account settings and MERL information.
          </p>
          <slot />
        </main>
      </div>
    </div>
    <Toast />
  </div>
</template>
